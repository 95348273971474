<template>

  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      spinner="bar-fade-scale"
      text="Carregando Campanhas"
    />

    <div v-if="!campanhas.length && !carregamentoApi">

    <b-col
      cols="12"
      style="padding: 0px;"
    >
      <b-card
        :no-body="true"
        class="text-left p-3"
        style="overflow: hidden;"
      >
        
        <b-card-body>

          <div style="position: absolute; top: 0; right: 0;z-index:0;">
            <img src="@/assets/images/bg/bg.png"/>
          </div>

          <div style="position: relative;z-index:2;">
            <b-card-title class="text-white">
              <h1 class="content-header-title">Bem-vindo às suas <b>audições</b></h1>
            </b-card-title>
            <b-card-text class="my-2">
              <h6>
                <b>Aqui você pode criar audições para buscar por hits que se encaixem perfeitamente no seu projeto.</b>
              </h6>
            </b-card-text>

            <b-button
              class="bg-gradient-primary mt-1"
              variant="primary"
              :to="{ name: 'campanha-cadastrar'}"
            >
              Criar Audição
            </b-button>
          </div>

        </b-card-body>

      </b-card>
    </b-col>

  </div>

    <section
      id="knowledge-base-content"
      class="pb-5"
    >

      <b-row>

        <b-col
          v-for="campanha in campanhas"
          :key="campanha.id"
          cols="12"
          xl="4"
        >

          <b-card
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center position-relative">
              <b-badge
                :variant="statusAprovacaoVariant(campanha.statusAprovacao)"
                pill
                class="position-absolute top-0 start-0 mt-1 ml-1"
              >
                {{ statusAprovacaoLabel(campanha.statusAprovacao) }}
              </b-badge>
              <b-link
                :to="{ name: 'campanha-comprador-visualizar', params: { id: campanha.id } }"
              >
                <v-lazy-image
                  fluid
                  class="card-img-top"
                  :src="campanha.arquivo"
                  :src-placeholder="require(`@/assets/images/placeholder/campanhas.gif`)"
                />
              </b-link>
            </div>

            <!-- Product Details -->
            <b-card-body>
              <h6 class="item-name">
                <b-link
                  class="text-body"
                  :to="{ name: 'campanha-comprador-visualizar', params: { id: campanha.id } }"
                >
                  {{ campanha.nome }}
                </b-link>
                
              </h6>
              <b-card-text class="item-description">
                {{ campanha.validade }}
              </b-card-text>
            </b-card-body>

            <!-- Action Buttons -->
            <div class="item-options text-center">
              <b-button
                variant="primary"
                class="btn-cart move-cart"
                style="border-radius: 0px 0px 8px 8px !important;width: 100%;"
                :to="{ name: 'campanha-comprador-visualizar', params: { id: campanha.id } }"
              >
                <span>Ver Audição</span>
                <b-icon
                  icon="arrow-right-short"
                  class="mr-50"
                />
              </b-button>
            </div>
          </b-card>
        </b-col>

      </b-row>

    </section>

  </div>
</template>

<script>

import router from '@/router'

import {
  BImgLazy, BContainer, BLink, BImg, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import moment from 'moment'

import axios from 'axios'

import VLazyImage from 'v-lazy-image/v2';

export default {
  components: {
    BImgLazy,
    BContainer,
    BLink,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VueElementLoading,
    moment,
    VLazyImage,
  },
  data() {
    return {

      campanhas: {},
      carregamentoApi: false,

    }
  },
  computed: {

    // this.hitsLista();

  },
  created() {
    this.campanhaLista()
  },

  methods: {

    // STATUS

    statusAprovacaoVariant(status) {
      if (status === 'EM_ANALISE') return 'primary'
      if (status === 'APROVADO') return 'success'
      if (status === 'REPROVADO') return 'danger'
      return 'warning'
    },

    statusAprovacaoLabel(status) {
      if (status === 'EM_ANALISE') return 'Em análise'
      if (status === 'APROVADO') return 'Aprovado'
      if (status === 'REPROVADO') return 'Reprovado'
      return 'Em análise'
    },

    // STATUS

    verCampanha(campanha) {
      this.$router.push({ name: 'campanha-visualizar', params: { campanha } })
    },

    formatarData(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },

    campanhaLista() {
      this.carregamentoApi = true

      useJwt.campanhaLista({
        page: 0,
        size: 10,
        sort: 'DESC',
      })
        .then(response => {

          if (response.data.content.length) {
            this.campanhas = response.data.content
          } else {
            this.campanhas = {}
          }

        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.card-campanha .card-title{
  font-size: 14px;
}

.card-campanha .card-subtitle {
  font-size: 12px;
}

</style>
